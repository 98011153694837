/**
 *
 * NPS/Button
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ThumbUpIcon from 'components/HelpfulContainer/images/thumbup.svg';
import ThumbDownIcon from 'components/HelpfulContainer/images/thumbdown.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D8D8D8',
    borderRadius: 10,
    marginTop: 4,
    marginBottom: 4,
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  selectedWrapper: {
    borderColor: '#01619B',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 45,
    padding: '9px 13px',
    borderRadius: 5,
    width: '100%',
  },
  containerWithIcon: {
    height: 64,
    padding: 18,
    [theme.breakpoints.down('xs')]: {
      height: 45,
    },
  },
  selecterOtherContainer: {
    paddingBottom: 4,
    height: 40,
  },
  label: {
    marginLeft: 27,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 12,
    },
  },
  icon: {
    marginLeft: 27,
    height: 24,
    width: 24,
    marginBottom: 1,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
      height: 20,
      width: 20,
    },
  },
  circle: {
    height: 29,
    width: 29,
    border: '1px solid #D8D8D8',
    borderRadius: 15,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 22,
      width: 22,
    },
  },
  checkbox: {
    height: 28,
    width: 28,
    border: '1px solid #D8D8D8',
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 22,
      width: 22,
    },
  },
  selected: {
    borderColor: '#01619B',
    backgroundColor: '#01619B',
  },
  checkIcon: {
    color: '#FFFFFF',
    fontSize: 20,
  },
  input: {
    width: 'calc(100% - 82px)',
    marginLeft: 13 + 29 + 27,
    marginRight: 13,
    marginBottom: 8,
    ...theme.typography.overline,
    color: theme.palette.text.primary,
    borderBottom: '1px solid #D8D8D8',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 60px)',
      marginLeft: 13 + 22 + 12,
      marginRight: 13,
    },
  },
}));

const Button = ({
  iconName,
  label,
  value,
  onClick,
  type,
  other,
  otherValue,
  onTextChange,
  placeholder,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  const toggleSelection = () => {
    if (type === 'radio') {
      if (!selected)
        setTimeout(() => {
          onClick(value, label);
        }, 300);
      else onClick(value, label);

      setSelected(!selected);
    } else if (type === 'checkbox') {
      onClick(value, label, other);
      setSelected(!selected);
    }
  };

  let icon;
  if (iconName === 'thumbs-up') icon = ThumbUpIcon;
  else if (iconName === 'thumbs-down') icon = ThumbDownIcon;

  return (
    <div
      className={_classNames({
        [classes.wrapper]: true,
        [classes.selectedWrapper]: selected,
      })}
    >
      <ButtonBase
        onClick={e => toggleSelection(e)}
        disableRipple
        classes={{
          root: _classNames({
            [classes.container]: true,
            [classes.containerWithIcon]: icon,
            [classes.selecterOtherContainer]: !icon && other && selected,
          }),
        }}
      >
        <div
          className={_classNames({
            [classes.circle]: type !== 'checkbox',
            [classes.checkbox]: type === 'checkbox',
            [classes.selected]: selected,
          })}
        >
          {selected && <CheckIcon className={classes.checkIcon} />}
        </div>
        {icon && <img alt="" className={classes.icon} src={icon} />}
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </ButtonBase>
      {other && selected && (
        <InputBase
          className={classes.input}
          value={otherValue}
          onChange={e => onTextChange(e)}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

Button.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.number,
  type: PropTypes.string,
  otherValue: PropTypes.string,
  other: PropTypes.bool,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Button;
