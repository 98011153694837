import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowIcon from 'components/DiscoverLink/right-arrow.svg';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginTop: props => (typeof props.mt === 'number' ? props.mt : 20),
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  btnRoot: {
    width: 'fit-content',
    marginTop: props => (typeof props.mt === 'number' ? props.mt : 0),
  },
  makeBtn: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 42,
      background: theme.palette.primary.main,
      color: 'white',
      borderRadius: 3,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  icon: {
    marginLeft: 5,
    marginBottom: 2,
  },
  hideXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

function ViewLink({
  label,
  link,
  mt,
  isTextOnly,
  clickHandler,
  button,
  className,
}) {
  const classes = useStyles({ mt });

  if (button) {
    return (
      <ButtonBase
        onClick={clickHandler && clickHandler}
        className={ClassNames(
          classes.root,
          button && classes.btnRoot,
          !isTextOnly && classes.makeBtn,
          className,
        )}
        disableRipple
      >
        {label}
        <img
          src={ArrowIcon}
          alt=""
          className={ClassNames(classes.icon, !isTextOnly && classes.hideXs)}
        />
      </ButtonBase>
    );
  }

  return (
    <Link
      to={link}
      className={ClassNames(
        classes.root,
        !isTextOnly && classes.makeBtn,
        className,
      )}
      onClick={clickHandler && clickHandler}
    >
      {label}
      <img
        src={ArrowIcon}
        alt=""
        className={ClassNames(classes.icon, !isTextOnly && classes.hideXs)}
      />
    </Link>
  );
}

ViewLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  mt: PropTypes.number,
  isTextOnly: PropTypes.bool,
  clickHandler: PropTypes.func,
};

export default ViewLink;
