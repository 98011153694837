import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { regenerateAnonymousId } from 'containers/Auth/actions';
import _get from 'lodash/get';
import ButtonBase from '@material-ui/core/ButtonBase';
import { setClinicMode, setIsEndingSession } from 'containers/Main/actions';
import { makeSelectClinicMode } from 'containers/Main/selectors';
import _classNames from 'classnames';
import useSiteCopySelector from 'components/useSiteCopySelector';
import TimerIcon from '@material-ui/icons/Timer';
import { useHistory } from 'react-router-dom';
import AtTheClinicWhite from './images/atTheClinicWhite.svg';
import AtTheClinicDark from './images/atTheClinicDark.svg';
import {
  cleanAllCookies,
  removeLocalData,
  isPublicMode,
} from '../../utils/localDataStore';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#E3E3E3',
    color: '#9c9c9c',
    borderRadius: 15,
    textTransform: 'none',
    minWidth: 109,
    paddingLeft: 7,
    paddingRight: 7,
    height: 25,
    fontFamily: 'MadaRegular',
    '& img': {
      marginRight: 5,
      marginTop: -3,
    },
  },
  active: {
    backgroundColor: '#35A430',
    color: '#FFFFFF',
  },
  timer: {
    marginLeft: 10,
    background: '#01619B',
    color: '#FFFFFF',
    '& svg': {
      marginRight: 5,
    },
  },
});

const AtTheClinicButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const clinicMode = useSelector(makeSelectClinicMode());
  const [assessmentSiteCopy] = useSiteCopySelector(['assessment']);
  const isPublicDevice = isPublicMode();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPublicDevice) {
      dispatch(setClinicMode(true));
    }
  }, [isPublicDevice, dispatch]);

  // eslint-disable-next-line consistent-return
  const handleClick = () => {
    if (isPublicDevice) return false;
    dispatch(setClinicMode(!clinicMode));
  };

  const endSession = () => {
    dispatch(setIsEndingSession(true));
    removeLocalData('userIdTemp');
    cleanAllCookies();
    dispatch(regenerateAnonymousId());
    dispatch(setIsEndingSession(false));
    history.replace(`${isPublicDevice ? '/?mode=public' : '/'}`, {
      showReminderOverlay: false,
    });
  };
  return (
    <>
      <ButtonBase
        className={_classNames({
          [classes.button]: true,
          [classes.active]: clinicMode,
        })}
        onClick={handleClick}
        aria-label={`Turn ${clinicMode ? 'Off' : 'On'} clinic mode`}
      >
        <img alt="" src={clinicMode ? AtTheClinicWhite : AtTheClinicDark} />
        <span>{_get(assessmentSiteCopy, 'pageCopy.atTheClinicLabel')}</span>
      </ButtonBase>
      {clinicMode && (
        <ButtonBase
          className={_classNames({
            [classes.button]: true,
            [classes.timer]: true,
          })}
          onClick={endSession}
        >
          <TimerIcon fontSize="small" />
          <span>{_get(assessmentSiteCopy, 'pageCopy.endSessionLabel')}</span>
        </ButtonBase>
      )}
    </>
  );
};

export default React.memo(AtTheClinicButton);
