import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ClassNames from 'classnames';
import useShortMenu from 'components/useShortMenu';
import { useHistory, Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import SearchBar from 'components/SearchBar';
import { isIE } from 'utils/stringUtils';
import useWindowSize from 'components/useWindowSize';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import LanguageBanner from 'components/LanguageBanner';
import _get from 'lodash/get';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { isEmbedded } from 'utils/embedded';
import Logo from './images/small-logo.svg';
import SearchIcon from './images/search.svg';

const useStyles = makeStyles(theme => ({
  root: {
    height: 36,
    zIndex: 999,
    background: '#F8F9F9',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.isIE ? 'space-between' : 'center'),
  },
  container: {
    display: 'flex',
    justifyContent: props => (props.isIE ? 'space-between' : 'center'),
    alignItems: 'center',
    width: '100%',
    padding: '0 5%',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: '0 14px',
    },
  },
  btnsWrapper: {
    display: 'flex',
    position: props => (props.isIE ? 'inherit' : 'absolute'),
    left: 0,
    width: '100%',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& > svg': {
      fill: theme.palette.text.primary,
      fontSize: 20,
    },
    color: '#848484',
    fontSize: 14,
    fontFamily: 'MadaRegular',

    [theme.breakpoints.down('1140')]: {
      paddingLeft: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2%',
    },
  },
  search: {
    position: props => (props.isIE ? 'inherit' : 'absolute'),
    right: 0,
    [theme.breakpoints.down('1140')]: {
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '2%',
    },
  },
  searchBtn: {
    fontFamily: 'MadaRegular',
    color: '#848484',
    fontSize: 14,
    '& img': {
      marginLeft: 5,
    },
  },
  searchText: {
    [theme.breakpoints.down('480')]: {
      display: 'none',
    },
  },
  logoLink: {
    zIndex: 1,
  },
}));

const BackHeader = () => {
  const classes = useStyles({ isIE: isIE() });
  const history = useHistory();
  const { width } = useWindowSize();
  const [showSearch, setShowSearch] = useState(false);
  const { shortMenu } = useShortMenu();
  const clientDetails = useSelector(makeSelectClientDetails());
  const isMobile = width <= 600;
  const useAppEmbedStyle =
    isEmbedded() || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const shouldShowSearchBar = !_get(
    clientDetails,
    'metadata.hideSearchBar',
    false,
  );
  const currentPathName = _get(history, 'location.pathname');

  if (
    (!useAppEmbedStyle && (!isEmbedded() || isMobile)) ||
    currentPathName.includes('/client-admin')
  )
    return null;

  const back = () => {
    const isHomePage = currentPathName === '/';
    if (isHomePage) return;
    history.goBack();
  };

  return (
    <div className={ClassNames(classes.root, { [classes.sticky]: !shortMenu })}>
      <div className={classes.container}>
        <div className={classes.btnsWrapper}>
          <ButtonBase className={classes.backBtn} onClick={back}>
            <ChevronLeftIcon />
          </ButtonBase>
          <LanguageBanner />
        </div>
        {(!showSearch || width >= 600) && (
          <Link to="/" className={classes.logoLink}>
            <img src={Logo} alt="" />
          </Link>
        )}
        {shouldShowSearchBar && (
          <p className={classes.search}>
            {showSearch || width >= 600 ? (
              <SearchBar
                isFlat
                close={() => setShowSearch(false)}
                isEmbedded={useAppEmbedStyle}
                id="flat-search-bar"
                section="header"
              />
            ) : (
              <ButtonBase
                onClick={() => setShowSearch(true)}
                className={classes.searchBtn}
              >
                <span className={classes.searchText}>CredibleMind Search</span>
                <img src={SearchIcon} alt="" />
              </ButtonBase>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default BackHeader;
