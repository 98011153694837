import React from 'react';

export const ArrowDownMenuIcon = ({ isHovered }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M1.5 4L5.85326 7.94704C5.87162 7.96369 5.89418 7.97702 5.91944 7.98615C5.9447 7.99528 5.9721 8 5.9998 8C6.02751 8 6.05491 7.99528 6.08017 7.98615C6.10543 7.97702 6.12799 7.96369 6.14635 7.94704L10.5 4"
      stroke={isHovered ? '#01619B' : '#4B4B4B'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
