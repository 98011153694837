import React, { useContext } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useModalFocusTrap from 'components/Hooks/useModalFocusTrap';
import BlogsNewsLatestItem from './BlogsNewsLatestItem';
import BlogsNewsMainItem from './BlogsNewsMainItem';
import NavBarContext from '../../navBarContext';
import ViewLink from '../ViewLink';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '17px 34px',
  },
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: 28,
    gap: 21,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

function News({ onClose }) {
  const { news, siteCopy, sendMixpanelEvent } = useContext(NavBarContext);
  const { refCallback } = useModalFocusTrap(onClose, 'news');

  const classes = useStyles();

  const clickHandler = (name, element) => {
    sendMixpanelEvent('Menu Item - Clicked', {
      type: 'News',
      name,
      element,
    });
  };
  if (_isEmpty(news)) return null;

  return (
    <div className={classes.root} data-test-id="menu-news" ref={refCallback}>
      <div className={classes.rightContent} data-test-id="menu-news-latest">
        <div className={classes.headerContainer}>
          <Typography variant="h6" color="textPrimary">
            {_get(siteCopy, ['pageCopy', 'submenu-news', 'latest'])}
          </Typography>
          <ViewLink
            label={_get(siteCopy, ['pageCopy', 'submenu-news', 'view-all'])}
            link="/news"
            mt={0}
          />
        </div>
        <BlogsNewsMainItem
          data={news[0].fields}
          isLatest
          clickHandler={clickHandler}
          resourceType="news"
        />
        <div className={classes.lists}>
          {news.slice(1, 4).map(el => {
            const { slug } = el.fields;
            return (
              <BlogsNewsLatestItem
                data={el.fields}
                type="articles"
                key={slug}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default News;
