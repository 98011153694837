import React from 'react';
import PropTypes from 'prop-types';
import TrackedHtmlMedia from './TrackedHtmlMedia';

function HtmlMedia({ classes, node, ...props }) {
  const { title, file, description } = node.data.target.fields;
  const mimeType = file.contentType;
  const mimeGroup = mimeType.split('/')[0];

  switch (mimeGroup) {
    case 'image':
      return (
        <div className={classes.imgWrapper}>
          <img title={title} alt="" src={file.url} />
        </div>
      );
    case 'video':
    case 'audio':
      return (
        <TrackedHtmlMedia
          mimeGroup={mimeGroup}
          source={file.url}
          description={description}
          {...props}
        />
      );
    case 'application':
      return (
        <a alt="Rich Text Photo" href={file.url}>
          {title || file.details.fileName}
        </a>
      );
    default:
      return null;
  }
}

HtmlMedia.propTypes = {
  node: PropTypes.object,
};

export default HtmlMedia;
