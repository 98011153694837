import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

const TITLE_TYPES = ['book', 'events', 'podcast', 'topics', 'video'];

const getLinkMapping = (type, node) => {
  let finalType = type;
  if (type === 'source') {
    finalType = _get(node, 'data.target.fields.type');
  }
  const linkMapping = {
    person: 'people', // name
    book: 'books', // title
    application: 'apps', // name
    podcast: 'podcasts', // title
    activity: 'articles', // name
    video: 'videos', // title
    organization: 'organizations', // name
    topics: 'topics', // title
    reads: 'articles', // name
    events: 'events', // title
    FAQ: 'faqs',
    assessments: 'assessments',
    program: 'onlinePrograms',
  };

  return linkMapping[finalType];
};

export const getEntryDisplay = (node, fullpath = false) => {
  const file = _get(node, 'data.target.fields.file');
  // check if it contains media
  if (file && _has(file, 'contentType')) {
    return getHtmlMedia(node);
  }

  const reviewStatus = _get(node, ['data', 'target', 'fields', 'reviewStatus']);
  const isStatusAccepted = reviewStatus === 'Accepted';

  const link = isStatusAccepted
    ? `${fullpath ? window.location.origin : ''}/${getLinkMapping(
        _get(node, 'data.target.sys.contentType.sys.id'),
        node,
      )}/${_get(node, 'data.target.fields.slug')}`
    : '#';
  const hasContentTypes = !_isEmpty(_get(node, 'data.target.sys.contentType'));
  const contentValue = _get(node, 'content.0.value'); // for custom value
  const typeContent =
    hasContentTypes && node.data.target.sys.contentType.sys.id;
  const finalType = TITLE_TYPES.includes(typeContent)
    ? 'title'
    : typeContent === 'source'
    ? 'article'
    : 'name'; // check what field to use, title or name or article(source)
  if (!_has(node.data.target.fields, finalType)) return null;
  const finalValue = contentValue || node.data.target.fields[finalType];
  const linkClass = isStatusAccepted
    ? 'embedded-link'
    : 'embedded-link-inactive';
  return `<a href="${link}" class="${linkClass}">
  ${finalValue}</a>`;
};

export const getHtmlMedia = node => {
  const file = _get(node, 'data.target.fields.file');
  const mimeType = file.contentType;
  const mimeGroup = mimeType.split('/')[0];
  switch (mimeGroup) {
    case 'video':
      return `<div><video controls>
            <source src="${file.url}" type="video/mp4" />
            <p>Your browser doesnt support HTML5 video.</p>
          </video></div>`;
    case 'audio':
      return `<div><audio controls src="${file.url}">
                  Your browser does not support the
                  <code>audio</code> element.
              </audio></div>`;
    case 'image':
      return `<img src="${file.url}" alt="" />`;

    default:
      return ``;
  }
};
