import React, { useState, useContext } from 'react';
import qs from 'qs';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ClassNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useHistory, useLocation } from 'react-router-dom';
import NavBarContext from '../navBarContext';
import UserActive from '../images/user-active.svg';
import UserInActive from '../images/user-inactive.svg';
import SignInActive from '../images/sign-in-active.svg';
import SignInInActive from '../images/sign-in-inactive.svg';
import DownArrowIcon from '../images/arrow-down-filled.svg';

const useStyles = makeStyles(theme => ({
  popper: {
    marginTop: 10,
    zIndex: 999,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #E3E3E3 transparent`,
      },
    },
  },
  paper: {
    borderRadius: '4px !important',
    boxShadow: 'none !important',
    border: '1px solid #E3E3E3',
  },
  list: {
    padding: 0,
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  button: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  text: {
    ...theme.typography.body2,
  },
  textActive: {
    color: theme.palette.primary.main,
  },
  listIcon: {
    minWidth: 37,
  },
  downIcon: {
    marginLeft: 10,
  },
}));

export default function LoginMenu({ trackUserMenuEvent }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { allowSignIn, siteCopy } = useContext(NavBarContext);
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex] = useState(0);

  if (!allowSignIn) return null;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleListItemClick = (event, index) => {
    setAnchorEl(null);
    let redirect = location.pathname;
    const params = qs.parse(location.search.slice(1));
    if (params.redirect) {
      ({ redirect } = params);
    }

    trackUserMenuEvent({
      type: index === 0 ? 'sign in' : 'sign up',
      url: index === 0 ? '/login' : '/signup',
    });

    if (index === 0) {
      history.push(`/login?redirect=${redirect}`);
    } else {
      history.push(`/signup?redirect=${redirect}`);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  return (
    <>
      <ButtonBase
        aria-describedby={id}
        onClick={handleClick}
        className={classes.button}
        data-test-id="auth-btn"
        disableRipple
      >
        {_get(siteCopy, ['pageCopy', 'submenu-labels', 'auth-cta'])}
        <img src={DownArrowIcon} alt="" className={classes.downIcon} />
      </ButtonBase>
      <>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          transition
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          className={classes.popper}
        >
          {() => (
            <>
              <span className={classes.arrow} ref={setArrowRef} />
              <ClickAwayListener onClickAway={handleClose}>
                <Paper className={classes.paper}>
                  <List className={classes.list}>
                    <ListItem
                      button
                      selected={selectedIndex === 0}
                      data-test-id="sign-in-nav-btn"
                      onClick={event => handleListItemClick(event, 0)}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <img
                          src={
                            selectedIndex === 0 ? SignInActive : SignInInActive
                          }
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={_get(siteCopy, [
                          'pageCopy',
                          'submenu-labels',
                          'login',
                        ])}
                        className={ClassNames(
                          classes.text,
                          selectedIndex === 0 ? classes.textActive : undefined,
                        )}
                      />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 1}
                      data-test-id="sign-up-nav-btn"
                      onClick={event => handleListItemClick(event, 1)}
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <img
                          src={selectedIndex === 1 ? UserActive : UserInActive}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={_get(siteCopy, [
                          'pageCopy',
                          'submenu-labels',
                          'join',
                        ])}
                        className={ClassNames(
                          classes.text,
                          selectedIndex === 1 ? classes.textActive : undefined,
                        )}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </ClickAwayListener>
            </>
          )}
        </Popper>
      </>
    </>
  );
}
