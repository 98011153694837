import { useRef } from 'react';

export const MENU_DROPDOWN_PREFIX = 'topics-menu-tab';
const FOCUSABLE_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const useTopicsKeyboardNavigation = index => {
  const modalRef = useRef(null);

  const handleKeyDown = event => {
    if (!modalRef.current) return;

    const topic = document.getElementById(`${MENU_DROPDOWN_PREFIX}-${index}`);

    const focusableElements = Array.from(
      modalRef.current.querySelectorAll(FOCUSABLE_SELECTOR),
    );

    if (topic) {
      focusableElements.push(topic);
    }

    if (focusableElements.length === 1) return;

    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();

      const currentIndex = focusableElements.indexOf(document.activeElement);
      let nextIndex;

      if (event.key === 'ArrowLeft') {
        nextIndex =
          currentIndex === 0 ? focusableElements.length - 1 : currentIndex - 1;
      } else {
        nextIndex =
          currentIndex === focusableElements.length - 1 ? 0 : currentIndex + 1;
      }

      focusableElements[nextIndex].focus();
    }
  };

  const refCallback = element => {
    if (!element) {
      document.removeEventListener('keydown', handleKeyDown);
      return;
    }
    modalRef.current = element;

    document.addEventListener('keydown', handleKeyDown);
  };

  return { refCallback };
};
