import React, { useEffect, useState } from 'react';
import _classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Config from 'utils/getEnvConfig';
import { addHttps, getImageFile } from 'utils/stringUtils';

const { API_URL, API_KEY } = Config.LISTEN_NOTES;

const useStyles = makeStyles(theme => ({
  practiceTitleWrapper: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  practiceWidgetContainer: {
    background: 'linear-gradient(81deg, #FFEFDA -23.74%, #F7D1D0 122.1%)',
    borderRadius: 8,
    padding: 20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  inlinePracticeWidgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1em',
    marginBottom: '1em',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  practiceTitle: {
    fontFamily: 'Mada !important',
    fontWeight: '700 !important',
    fontSize: '18px !important',
    lineHeight: '24px !important',
    color: theme.palette.text.primary,
  },
  practiceSubtitle: {
    fontFamily: 'Mada !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    textAlign: 'left !important',
    color: theme.palette.text.primary,
    marginBottom: ({ inline }) => (inline ? '8px !important' : 15),
    marginTop: ({ inline }) => (inline ? '-11px !important' : 0),
  },
  icon: {
    height: 30,
    marginRight: 15,
  },
  audio: {
    width: '100%',
    height: ({ inline }) => (inline ? 42 : 52),
  },
  image: {
    marginRight: 20,
    width: 100,
    height: 100,
    borderRadius: 12,
  },
  practiceInfoWrapper: {
    width: '100%',
  },
}));

const PracticeWidget = ({ data, inline = false }) => {
  const classes = useStyles({ inline });
  const [ready, setReady] = useState(false);
  const [resourceData, setResourceData] = useState();

  const hasResource = !_isEmpty(_get(data, 'fields.resource'));

  const icon = _get(data, 'fields.icon.fields.file.url');
  const title = _get(data, ['fields', inline ? 'title' : 'dashboardTitle']);
  const description = _get(data, [
    'fields',
    inline ? 'shortDescription' : 'dashboardDescription',
  ]);
  const image = getImageFile(data.fields);

  useEffect(() => {
    if (hasResource) {
      if (_get(data, 'fields.resource.sys.contentType.sys.id') === 'podcast') {
        const { listenNotesId = '', type = '' } = _get(
          data,
          'fields.resource.fields',
          {},
        );
        if (listenNotesId && type) {
          const endpoint = type === 'Episode' ? 'episodes' : 'podcasts';
          const apiUrl = `${API_URL}/${endpoint}/${listenNotesId}`;
          axios({
            method: 'get',
            url: apiUrl,
            headers: { 'X-ListenAPI-Key': API_KEY },
          }).then(response => {
            setResourceData(response.data);
          });
        }
      }
    } else {
      setResourceData(_get(data, 'fields.asset'));
    }
  }, []);

  const resource = () => {
    if (
      _get(resourceData, 'fields.file.contentType') &&
      resourceData.fields.file.contentType.startsWith('audio/')
    ) {
      return (
        <audio
          controls
          className={classes.audio}
          style={{ display: ready ? 'inline-block' : 'hidden' }}
          onLoadedMetadata={() => setReady(true)}
        >
          <source
            src={addHttps(_get(resourceData, 'fields.file.url'))}
            type={resourceData.fields.file.contentType}
          />
          Your browser does not support the audio element.
        </audio>
      );
    }
    return null;
  };

  return (
    <>
      <Box
        className={_classNames(classes.practiceWidgetContainer, {
          [classes.inlinePracticeWidgetContainer]: inline,
        })}
      >
        {inline && <img src={image} alt="" className={classes.image} />}
        <Box className={classes.practiceInfoWrapper}>
          <Box className={classes.practiceTitleWrapper}>
            {!inline && icon && (
              <img src={icon} alt="" className={classes.icon} />
            )}
            <Typography className={classes.practiceTitle}>{title}</Typography>
          </Box>
          {description && (
            <Typography className={classes.practiceSubtitle}>
              {description}
            </Typography>
          )}
          {!ready && <CircularProgress size={18} disableShrink />}
          {resource()}
        </Box>
      </Box>
    </>
  );
};

export default PracticeWidget;
