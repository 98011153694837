/* eslint-disable consistent-return, no-unused-expressions */
import { useCallback } from 'react';

const FOCUSABLE_SELECTOR = 'button, a, [tabindex="0"]';

export const useKeyboardNavigation = ({
  moreButtonId,
  moreItemIdPrefix,
  toggleShowmore,
  setShowMore,
}) =>
  useCallback(node => {
    if (!node) return;

    const handleKeyDown = e => {
      const { key, target } = e;
      const isMoreButton = target?.id === moreButtonId;
      const isMoreItem = target?.id?.includes(moreItemIdPrefix);
      const focusableItems = Array.from(
        node.querySelectorAll(FOCUSABLE_SELECTOR),
      );
      const moreButtonIndex = focusableItems.findIndex(
        el => el.id === moreButtonId,
      );

      if (
        isMoreButton &&
        (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter')
      ) {
        e.preventDefault();
        toggleShowmore(e);
        const currentFocusableItems = Array.from(
          node.querySelectorAll(FOCUSABLE_SELECTOR),
        );
        const currentIndex = currentFocusableItems.findIndex(
          el => el === document.activeElement,
        );
        if (currentFocusableItems?.[currentIndex + 1]) {
          currentFocusableItems[currentIndex + 1].focus();
        }
        return;
      }

      const navKeys = isMoreItem
        ? ['ArrowDown', 'ArrowUp']
        : ['ArrowRight', 'ArrowLeft'];

      if (!navKeys.includes(key)) {
        if (isMoreItem) {
          const moreItems = focusableItems.filter(el =>
            el.id.includes(moreItemIdPrefix),
          );

          if (key === 'Tab') {
            if (e.shiftKey) {
              focusableItems[moreButtonIndex + 1].focus();
            } else {
              const allFocusableItems = Array.from(
                document.querySelectorAll(FOCUSABLE_SELECTOR),
              );
              const lastMoreItem = moreItems.at(-1);
              const lastMoreItemIndex = allFocusableItems.findIndex(
                el => el === lastMoreItem,
              );
              if (allFocusableItems[lastMoreItemIndex]) {
                allFocusableItems[lastMoreItemIndex].focus();
              }
            }
            setShowMore(false);
          }

          if (
            ['ArrowRight', 'ArrowLeft', 'Escape'].includes(key) &&
            focusableItems
          ) {
            e.preventDefault();
            setShowMore(false);

            switch (key) {
              case 'ArrowRight':
                focusableItems[0]?.focus();
                break;
              case 'ArrowLeft':
                focusableItems[moreButtonIndex - 1]?.focus();
                break;
              case 'Escape':
                focusableItems[moreButtonIndex]?.focus();
                break;
              default:
                break;
            }
          }
        }

        return;
      }

      const itemsToNavigate = isMoreItem
        ? focusableItems.filter(el => el.id.includes(moreItemIdPrefix))
        : focusableItems;

      const currentIndex = itemsToNavigate.findIndex(
        el => el === document.activeElement,
      );
      if (currentIndex === -1) return;

      e.preventDefault();
      const delta = key === navKeys[0] ? 1 : -1;
      const nextIndex =
        (currentIndex + delta + itemsToNavigate.length) %
        itemsToNavigate.length;

      if (itemsToNavigate[nextIndex]) {
        itemsToNavigate[nextIndex]?.focus();
      }
    };

    node.addEventListener('keydown', handleKeyDown);
    return () => {
      node.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
