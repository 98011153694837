import { useRef } from 'react';

const MENU_DROPDOWN_PREFIX = 'menu-dropdown';
const FOCUSABLE_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const useModalFocusTrap = (onClose, slug) => {
  const modalRef = useRef(null);
  const firstRender = useRef(true);
  const refCallback = element => {
    if (!element) {
      document.removeEventListener('keydown', handleKeyDown);
      return;
    }
    modalRef.current = element;

    setTimeout(() => {
      const firstFocusable = element.querySelector(FOCUSABLE_SELECTOR);
      if (firstFocusable && firstRender.current) {
        firstFocusable.focus();
        firstRender.current = false;
      }
    });

    document.addEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = event => {
    if (!modalRef.current) return;

    const arrow = document.getElementById(`${MENU_DROPDOWN_PREFIX}-${slug}`);

    if (
      event?.target?.id?.includes(MENU_DROPDOWN_PREFIX) &&
      (event.key === 'ArrowLeft' || event.key === 'ArrowRight')
    ) {
      onClose();
    }
    const focusableElements = Array.from(
      modalRef.current.querySelectorAll(FOCUSABLE_SELECTOR),
    );

    if (arrow) {
      focusableElements.push(arrow);
    }

    if (focusableElements.length === 0) return;

    if (
      event.key === 'Tab' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      event.preventDefault();

      const currentIndex = focusableElements.indexOf(document.activeElement);
      let nextIndex;

      if (event.shiftKey || event.key === 'ArrowUp') {
        nextIndex =
          currentIndex === 0 ? focusableElements.length - 1 : currentIndex - 1;
      } else {
        nextIndex =
          currentIndex === focusableElements.length - 1 ? 0 : currentIndex + 1;
      }

      focusableElements[nextIndex].focus();
    }

    if (event.key === 'Escape') {
      document.getElementById(`${MENU_DROPDOWN_PREFIX}-${slug}`).focus();
      onClose();
    }
  };

  return { handleKeyDown, refCallback };
};

export default useModalFocusTrap;
