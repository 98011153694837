/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';
import RichTextReactRenderer from 'components/RichTextReactRenderer';
import StyledLink from 'components/StyledLink';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useWindowSize from 'components/useWindowSize';
import { getImageFile } from 'utils/stringUtils';
import DynamicContent from 'containers/Article/components/DynamicHtmlContent';
import RightArrow from '../../images/arrow-right.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('960')]: {
      flexDirection: 'column',
    },
  },
  img: {
    height: ({ imageHeight }) => imageHeight,
    width: ({ imageWidth }) => imageWidth,
    marginRight: 38,
    objectFit: 'cover',
    [theme.breakpoints.down('960')]: {
      width: '100%',
      height: 'auto',
      marginBottom: 20,
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 386,
    justifyContent: 'space-between',
    [theme.breakpoints.down('960')]: {
      maxWidth: '100%',
    },
  },
  name: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '3.75rem' /* Fallback for non-webkit */,
  },
  rtWrapper: {
    [theme.breakpoints.down('960')]: {
      margin: '20px 0',
    },
    '& p': {
      margin: 0,
      fontSize: '1.0625rem',
    },
  },
  btnLink: {
    textDecoration: 'none',
    background: '#ECF4F8',
    height: 38,
    width: 106,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      marginLeft: 5,
    },
  },
  richText: {
    '& h2,h3,h4': {
      fontSize: '1.0625rem',
      fontFamily: 'Mada',
    },
  },
}));

function BlogsNewsMainItem({ data, clickHandler, resourceType }) {
  const [siteCopy] = useSiteCopySelector(['resource-item-page']);
  const { height } = useWindowSize();

  const imageSrc = getImageFile(data);
  const { slug, name, description, htmlContent } = data;
  let imageHeight = 213;
  let imageWidth = 375;
  let richTextLimit = 200;
  if (height < 700) {
    imageHeight = 160;
    imageWidth = 350;
    richTextLimit = 80;
  }

  const classes = useStyles({ imageHeight, imageWidth });

  return (
    <StyledLink
      link={`/${resourceType === 'news' ? 'articles' : 'insights'}/${slug}`}
      ariaLabel={name}
    >
      <div className={classes.root} data-test-id="blog-main">
        <div role="button" onClick={() => clickHandler(name, 'image')}>
          <img src={imageSrc} alt="" className={classes.img} />
        </div>
        <div
          className={classes.info}
          role="button"
          onClick={() => clickHandler(name, 'text')}
        >
          <Typography
            className={classes.name}
            variant="subtitle2"
            color="textPrimary"
          >
            {name}
          </Typography>
          <div className={classes.rtWrapper}>
            {htmlContent ? (
              <DynamicContent htmlContent={htmlContent} limit={richTextLimit} />
            ) : (
              <RichTextReactRenderer
                data={description}
                limit={richTextLimit}
                hideReadText
                className={classes.richText}
              />
            )}
          </div>
          <div className={classes.btnLink}>
            {_get(siteCopy, ['pageCopy', 'readMoreLabel'])}
            <img src={RightArrow} alt="" />
          </div>
        </div>
      </div>
    </StyledLink>
  );
}

BlogsNewsMainItem.propTypes = {
  data: PropTypes.object,
  clickHandler: PropTypes.func,
  resourceType: PropTypes.string,
};

export default BlogsNewsMainItem;
