import { getStore } from 'configureStore';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { addHttps } from 'utils/stringUtils';
import _flatten from 'lodash/flatten';
import _some from 'lodash/some';
import _every from 'lodash/every';

/**
 * ? Type of SERVICES && ACTIVITY are automatically INTERNAL
 * ? -- returns SLUG as URL
 * ? If URL includes 'crediblemind.com' string, it is assumed to be INTERNAL
 * ? -- returns URL path without 'crediblemind.com'
 */

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

const getCustomLink = (item, clientShortName) => {
  const store = getStore();
  const { profile } = store.getState().firebase;

  const fallback = item?.url ?? '';

  const customLinks = _get(item, 'context.customLinks', {});
  const customLink = customLinks[clientShortName];

  if (customLink) {
    if (typeof customLink === 'string') {
      return customLink;
    }

    if (typeof customLink === 'object') {
      const isSAML = _get(profile, 'providerData.providerId').includes('saml');
      if (isSAML) {
        return customLink.saml || fallback;
      }
      return customLink.password || customLink.default || fallback;
    }
  }
  return fallback;
};

export const getClientLinkUrl = (item, clientDetails) => {
  const url = getCustomLink(item, clientDetails.shortName);
  return addHttps(url);
};

export const analyzeClientResource = (item, clientDetails) => {
  let url = getCustomLink(item, clientDetails.shortName);
  const slug = _get(item, 'slug') || '';
  const contentTypeSysId = (
    _get(item, 'sys.contentType.sys.id') || ''
  ).toLowerCase();
  const typename = (_get(item, '__typename') || '').toLowerCase();
  const type = (_get(item, 'type') || '').toLowerCase();
  const expandedType = (_get(item, 'expandedType') || '').toLowerCase();
  const urlObject = isValidHttpUrl(url) ? new URL(url) : {};
  const isCMLink =
    _get(urlObject, 'hostname') === 'crediblemind.com' || url.startsWith('/');

  const checker = [contentTypeSysId, typename, type, expandedType];
  const isActivity =
    checker.includes('activity') || checker.includes('articles');
  const isService = checker.includes('services');
  const isVideo = checker.includes('video');
  const isApp = checker.includes('application');
  const isPodcast = checker.includes('podcast');
  const isInternal =
    isActivity || isService || isCMLink || isVideo || isApp || isPodcast;

  if (isInternal && isActivity) url = `/articles/${slug}`;
  else if (isInternal && isService) url = `/services/${slug}`;
  else if (isInternal && isVideo) url = `/videos/${slug}`;
  else if (isInternal && isApp) url = `/apps/${slug}`;
  else if (isInternal && isPodcast) url = `/podcasts/${slug}`;
  else url = url.replace('https://crediblemind.com', '');

  return {
    isInternal,
    url,
  };
};

export const isClientAccepted = (item, clientDetails) => {
  const clientShortName = clientDetails.shortName;
  if (!clientShortName) {
    return _isEmpty(_get(item, 'fields.client', []));
  }

  const itemClients = _get(item, 'fields.client', []);
  const matchesClient = itemClients.some(
    client => _get(client, 'fields.shortName') === clientShortName,
  );
  if (itemClients.length && !matchesClient) return false;

  const itemExcludeClients = _get(item, 'fields.excludeClient', []);
  const matchesExcludeClient = itemExcludeClients.some(
    client => _get(client, 'fields.shortName') === clientShortName,
  );
  return !matchesExcludeClient;
};

export const filterBasedOnRequiredTags = ({
  items,
  insuranceTags,
  insuranceOptions,
  profileTags,
  requiredTagsOptions,
  isSearchPreview = false,
}) => {
  // Handle null or undefined inputs with default empty arrays
  const safeInsuranceOptions = insuranceOptions || [];
  const safeInsuranceTags = insuranceTags || [];
  const safeProfileTags = profileTags || [];
  const safeRequiredTagsOptions = requiredTagsOptions || [];

  // Extract tag IDs from options and filters
  const insuranceTagsOptionsIds = _flatten(
    safeInsuranceOptions.map(option => option.tags),
  );
  const requiredTagsOptionsIds = _flatten(
    safeRequiredTagsOptions.map(option => option.tags),
  );
  const insuranceTagsIds = safeInsuranceTags.map(tag => tag.id);
  const requiredTagsIds = safeProfileTags.map(tag => tag.id);

  const filteredItems = items.filter(item => {
    let insuranceCondition;
    let requiredTagsCondition;

    /**
     * If both insurance tags and insurance options are provided, assess item tags match
     * If insurance options are not provided, then insurance tags doesn't apply to current client
     * If insurance tags are not provided, then user might have insurance and should still see items
     *  */
    if (!_isEmpty(insuranceTagsIds) && !_isEmpty(insuranceTagsOptionsIds)) {
      const itemInsuranceTagsIds = (
        _get(item, isSearchPreview ? 'requiredTags' : 'fields.requiredTags') ||
        []
      )
        .map(tag => (isSearchPreview ? tag : tag.sys.id))
        .filter(tag => insuranceTagsOptionsIds.includes(tag));

      if (_isEmpty(itemInsuranceTagsIds)) {
        insuranceCondition = true;
      } else {
        const shouldMatchAll =
          _get(
            item,
            isSearchPreview ? 'requiredTagsLogic' : 'fields.requiredTagsLogic',
          ) === 'Match all tags';
        insuranceCondition = shouldMatchAll
          ? _every(itemInsuranceTagsIds, id => insuranceTagsIds.includes(id))
          : _some(itemInsuranceTagsIds, id => insuranceTagsIds.includes(id));
      }
    } else {
      insuranceCondition = true;
    }

    /**
     * If required tags options are not provided, then required tags doesn't apply to current client
     * If required tags options are provided, assess items visibility based on them
     * If user has no required tags, then user should not see items that have required tags assigned
     */
    if (!_isEmpty(requiredTagsOptionsIds)) {
      const itemRequiredTagsIds = (
        _get(item, isSearchPreview ? 'requiredTags' : 'fields.requiredTags') ||
        []
      )
        .map(tag => (isSearchPreview ? tag : tag.sys.id))
        .filter(tag => requiredTagsOptionsIds.includes(tag));

      if (_isEmpty(itemRequiredTagsIds)) {
        requiredTagsCondition = true;
      } else if (_isEmpty(requiredTagsIds)) {
        requiredTagsCondition = false;
      } else {
        const shouldMatchAll =
          _get(
            item,
            isSearchPreview ? 'requiredTagsLogic' : 'fields.requiredTagsLogic',
          ) === 'Match all tags';
        requiredTagsCondition = shouldMatchAll
          ? _every(itemRequiredTagsIds, id => requiredTagsIds.includes(id))
          : _some(itemRequiredTagsIds, id => requiredTagsIds.includes(id));
      }
    } else {
      requiredTagsCondition = true;
    }

    return insuranceCondition && requiredTagsCondition;
  });

  return filteredItems;
};
