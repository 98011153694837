import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import HeartFullIcon from '../SaveAsFavorite/images/heart-full.svg';
import HeartEmptyIcon from '../SaveAsFavorite/images/heart-empty.svg';

export const SaveFavoriteButton = ({
  saved,
  classes,
  handleSave,
  title,
  fullIcon,
  emptyIcon,
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };
  return saved ? (
    <IconButton
      className={classes.iconButton}
      onClick={handleSave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disableRipple
      disableFocusRipple
      aria-label={`Unsave ${title}`}
      aria-hidden={focused ? 'false' : 'true'}
    >
      <img src={fullIcon || HeartFullIcon} alt="" className={classes.icon} />
    </IconButton>
  ) : (
    <IconButton
      className={classes.iconButton}
      onClick={handleSave}
      disableRipple
      disableFocusRipple
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-hidden={focused ? 'false' : 'true'}
      aria-label={`Save ${title}`}
    >
      <img src={emptyIcon || HeartEmptyIcon} alt="" className={classes.icon} />
    </IconButton>
  );
};
