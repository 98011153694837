import React, { useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const MenuItemTooltip = ({
  popperState,
  anchorEl,
  classes,
  content,
  onClose,
}) => {
  const popperRef = useRef(null);

  return (
    <ClickAwayListener onClickAway={popperRef.current && onClose}>
      <Popper
        open={popperState.open}
        anchorEl={anchorEl}
        className={classes.popover}
        placement={popperState.placement}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            padding: 20,
          },
        }}
        disablePortal
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={400}>
            <div ref={popperRef} className={classes.paper}>
              {content(popperState.slug)}
            </div>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default MenuItemTooltip;
