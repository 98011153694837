import React, { useContext } from 'react';
import _get from 'lodash/get';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Mixpanel from 'utils/mixpanelService';
import ClassNames from 'classnames';
import Typogragphy from '@material-ui/core/Typography';
import NavBarContext from '../navBarContext';
import { getLogoComponent, getLogos } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  verticalDivider: {
    borderRight: '1px solid #E3E3E3',
    margin: '0 10px',
  },
  logo: {
    maxHeight: 72,
    [theme.breakpoints.down('1140')]: {
      maxHeight: 60,
    },
    [theme.breakpoints.down('1000')]: {
      maxHeight: 50,
    },
    [theme.breakpoints.down('950')]: {
      maxHeight: 45,
    },
    [theme.breakpoints.down('880')]: {
      maxHeight: 40,
    },
    [theme.breakpoints.down('768')]: {
      maxHeight: 45,
    },
    [theme.breakpoints.down('440')]: {
      maxHeight: 30,
    },
  },
  logoRoot: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  clientLogo: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 140,
    },
  },
  adminTypo: {
    color: '#6b6b6b',
    marginLeft: '10px',
    fontStyle: 'italic',
    fontSize: '1.05rem',
  },
  rightLogo: {
    maxHeight: 51,
    [theme.breakpoints.down('1140')]: {
      maxHeight: 42,
    },
    [theme.breakpoints.down('1000')]: {
      maxHeight: 35,
    },
    [theme.breakpoints.down('950')]: {
      maxHeight: 32,
    },
    [theme.breakpoints.down('880')]: {
      maxHeight: 28,
    },
    [theme.breakpoints.down('768')]: {
      maxHeight: 32,
    },
    [theme.breakpoints.down('440')]: {
      maxHeight: 21,
    },
  },
}));

function Logo({ isAdmin, alternative, rightLogo }) {
  const classes = useStyles();
  const location = useLocation();
  const { clientDetails, clientLanding, clientFetching, siteCopy } = useContext(
    NavBarContext,
  );
  const isClientActive = _get(clientDetails, 'activeClient') ?? true;
  const shouldShowNavBarSubMenu = !_get(
    clientDetails,
    'metadata.hideNavBarSubMenu',
    false,
  );
  if (clientFetching) return null;
  const { logos, logosUrl, clientUrl, hasLogosSet } = getLogos(
    clientDetails,
    clientLanding,
    isAdmin,
  );
  const redirect = () => {
    Mixpanel.track('Source Exit - Clicked', {
      page: location.pathname,
      exitPage: clientUrl,
      component: 'NavBar',
    });
  };

  const logoSrc =
    rightLogo && logos.length === 2
      ? logos[1]
      : rightLogo && logos.length === 3
      ? logos[2]
      : logos[0];
  const logoUrl =
    rightLogo && logosUrl.length === 2
      ? logosUrl[1]
      : rightLogo && logosUrl.length === 3
      ? logosUrl[2]
      : logosUrl[0];
  const logoImg = (
    <img
      src={logoSrc}
      alt=""
      className={ClassNames(classes.logo, {
        [classes.rightLogo]: rightLogo,
      })}
    />
  );

  const LogoComponent = isClientActive
    ? getLogoComponent({
        url: logoUrl,
        image: logoImg,
        onClick: redirect,
        className: classes.logoRoot,
        shouldRedirectOnClientUrl: _get(
          clientDetails,
          'metadata.shouldRedirectOnClientUrl',
        ),
      })
    : logoImg;

  const secondaryLogo = () => {
    if (isClientActive) {
      return (
        <>
          <div
            className={ClassNames({ [classes.verticalDivider]: !isAdmin })}
          />
          <a
            href={logosUrl[1]}
            className={classes.logoRoot}
            target="_blank"
            rel="noopener"
            aria-label={`Open ${logosUrl[1]} in a new tab`}
            onClick={redirect}
          >
            {isAdmin ? (
              <Link to="/admin" target="_blank">
                <Typogragphy className={classes.adminTypo}>
                  {_get(siteCopy, 'pageCopy.dialog-menu.menu-label')}
                </Typogragphy>
              </Link>
            ) : (
              <img
                src={logos[1]}
                alt=""
                className={ClassNames(classes.logo, classes.clientLogo)}
              />
            )}
          </a>
        </>
      );
    }

    return (
      <>
        <div className={ClassNames({ [classes.verticalDivider]: !isAdmin })} />
        {isAdmin ? (
          <Typogragphy className={classes.adminTypo}>
            {_get(siteCopy, 'pageCopy.dialog-menu.menu-label')}
          </Typogragphy>
        ) : (
          <img
            src={logos[1]}
            alt=""
            className={ClassNames(classes.logo, classes.clientLogo)}
          />
        )}
      </>
    );
  };

  const hasSecondaryLogo =
    !rightLogo && ((!alternative && logos.length === 2) || logos.length === 3);
  const hasLinkOnLogo =
    (!rightLogo && !!LogoComponent) ||
    (hasLogosSet && logos.length === logosUrl.length);

  return (
    <div className={classes.root}>
      <>{shouldShowNavBarSubMenu && hasLinkOnLogo ? LogoComponent : logoImg}</>
      {hasSecondaryLogo && secondaryLogo()}
    </div>
  );
}

export default Logo;
