import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeSelectLandingMenu,
  makeSelectClientDetails,
} from 'containers/Main/selectors';
import { setLandingMenu } from 'containers/Main/actions';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from '@material-ui/core/styles';
import ClassNames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import StyledLink from 'components/StyledLink';
import Modal from '@material-ui/core/Modal';
import CloseIcon from './close.svg';
import DownArrowIcon from './down-arrow.svg';

const useStyles = makeStyles(theme => ({
  popover: {
    marginTop: 10,
    zIndex: 9999,
  },
  root: {
    background: '#FFFFFF',
    position: 'relative',
    maxWidth: 766,
    margin: '0 auto',
    top: '15%',
    padding: 49,
    [theme.breakpoints.down('768')]: {
      margin: '0 5%',
      padding: 40,
      top: '10%',
    },
    [theme.breakpoints.down('450')]: {
      top: '5%',
      padding: 30,
    },
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 5,
    [theme.breakpoints.down('450')]: {
      right: 5,
      top: 0,
    },
  },
  contentWrapper: {
    display: 'flex',
    marginTop: 27,
    justifyContent: 'center',
    [theme.breakpoints.down('450')]: {
      flexDirection: 'column',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid #E3E3E3',
    height: 177,
    width: 177,
    margin: '0 11px',
    justifyContent: 'center',
    [theme.breakpoints.down('450')]: {
      width: '100%',
      margin: '0 0 10px 0',
    },
  },
  active: {
    border: '3px solid #01619B',
  },
  img: {
    width: 'auto',
    height: 48,
    objectFit: 'cover',
    marginBottom: 22,
  },
  selectedIcon: {
    height: 12,
    marginRight: 9,
    width: 'auto',
  },
  downIcon: {
    marginLeft: 10,
  },
  button: {
    ...theme.typography.overline,
    color: '#6C6C6C',
    justifyContent: 'flex-start',
  },
}));

const stateSelector = createStructuredSelector({
  landingMenu: makeSelectLandingMenu(),
  clientDetails: makeSelectClientDetails(),
});

function sortAndFilter(items) {
  return items
    .filter(({ showMenu }) => showMenu === 'yes')
    .sort((a, b) => a.menuOrder - b.menuOrder);
}

function getSelectedTitle(items, selectedSlug) {
  return items.filter(({ slug }) => slug === selectedSlug);
}

function LandingMenuSelector() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { clientDetails, landingMenu } = useSelector(stateSelector);

  const landingItems = _get(
    clientDetails,
    'landingLinksCollection.items',
    [],
  ).filter(item => !_isEmpty(item));

  useEffect(() => {
    const { pathname } = location;
    const isLandingPage = pathname.includes('/landing/');
    const landingSlug = location.pathname.split('/landing/')[1];

    const landingSlugs = landingItems.map(item => _get(item, 'slug'));
    // const navMenu = ['covid-19', 'boost', 'beyond-covid'];
    if (isLandingPage && landingSlugs.includes(landingSlug)) {
      dispatch(setLandingMenu(landingSlug));
    }
  }, [dispatch, location, landingItems]);

  const finalData = useCallback(sortAndFilter(landingItems), [clientDetails]);
  const selectedMenu = useCallback(getSelectedTitle(finalData, landingMenu), [
    location,
    landingMenu,
  ]);
  const selectedMenuTitle =
    _get(selectedMenu, '0.menuTitle') || _get(finalData, '0.menuTitle');
  const selectedMenuIcon =
    _get(selectedMenu, '0.menuImage.url') || _get(finalData, '0.menuImage.url');
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const setLanding = slug => {
    dispatch(setLandingMenu(slug));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'landing-popper' : undefined;

  const isClientActive = _get(clientDetails, 'activeClient') ?? true;
  if (!isClientActive) return null;

  return (
    <>
      <ButtonBase
        aria-describedby={id}
        className={classes.button}
        onClick={handleClick}
        disableRipple
      >
        <img src={selectedMenuIcon} alt="" className={classes.selectedIcon} />
        {selectedMenuTitle}
        <img src={DownArrowIcon} className={classes.downIcon} alt="" />
      </ButtonBase>
      <Modal open={open} onClose={handleClick}>
        <div className={classes.root}>
          <ButtonBase
            onClick={handleClick}
            aria-label="Close Modal"
            className={classes.closeBtn}
          >
            <img src={CloseIcon} alt="" className={classes.closeBtn} />
          </ButtonBase>

          <Typography className={classes.title} align="center" variant="h4">
            What kind of user are you?
          </Typography>
          <div className={classes.contentWrapper}>
            {finalData.map(({ menuTitle, menuImage, slug }) => (
              <StyledLink
                link={`/landing/${slug}`}
                onClick={() => setLanding(slug)}
                key={slug}
              >
                <div
                  className={ClassNames(classes.content, {
                    [classes.active]: selectedMenuTitle === menuTitle,
                  })}
                >
                  <img src={menuImage.url} alt="" className={classes.img} />
                  <Typography
                    color="textPrimary"
                    variant="overline"
                    align="center"
                  >
                    {menuTitle}
                  </Typography>
                </div>
              </StyledLink>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(LandingMenuSelector);
