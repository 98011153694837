import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export function getElementWordCount(element) {
  if (!element) return null;
  if (!_isEmpty(_get(element, 'props.children'))) {
    if (typeof element.props.children === 'string')
      return element.props.children.split(' ').length;
    if (Array.isArray(element.props.children))
      return element.props.children.reduce((accumulator, child) => {
        if (child.type === 'span' && typeof child.props.children === 'string') {
          return accumulator + child.props.children.split(' ').length;
        }
        if (typeof child === 'string') {
          return accumulator + 1;
        }

        return accumulator + getElementWordCount(child.props.children);
      }, 0);
  }

  return 0;
}

export function getElementTextLength(element) {
  if (!element) return null;
  if (!_isEmpty(_get(element, 'props.children'))) {
    if (
      typeof element.props.children === 'string' ||
      typeof _get(element.props.children, 'props.children') === 'string'
    )
      return (
        element.props.children.length ||
        element.props.children.props.children.length
      );
    if (Array.isArray(element.props.children)) {
      const traverseChildren = children =>
        children.reduce((accumulator, child) => {
          if (
            child.type === 'span' &&
            typeof child.props.children === 'string'
          ) {
            return accumulator + child.props.children.length;
          }
          if (
            _get(child.props, 'children') &&
            Array.isArray(child.props.children)
          ) {
            return traverseChildren(child.props.children);
          }
          if (typeof child === 'string') {
            return accumulator + child.length;
          }
          if (Array.isArray(child)) {
            return traverseChildren(child);
          }

          return accumulator + getElementTextLength(child.props.children);
        }, 0);

      return traverseChildren(element.props.children);
    }
  } else if (typeof element === 'string') {
    return element.length;
  }

  return 0;
}

const createTruncatedTextNode = (
  text,
  length,
  readMore,
  color,
  hideReadText,
  readMoreLabel,
  key,
) => (
  <React.Fragment key={`${key}-truncated`}>
    <span>{text.substring(0, length)}...</span>
    {!hideReadText && (
      <span
        role="button"
        onClick={readMore}
        onKeyDown={readMore}
        tabIndex={0}
        style={{
          cursor: 'pointer',
          color: color || '#01619B',
        }}
      >
        &nbsp;{readMoreLabel}
      </span>
    )}
  </React.Fragment>
);

export function getPartialElement(
  element,
  len,
  readMore,
  color,
  hideReadText,
  readMoreLabel,
  key,
) {
  if (!element) return null;

  if (typeof element === 'string') {
    return createTruncatedTextNode(
      element,
      len,
      readMore,
      color,
      hideReadText,
      readMoreLabel,
      key,
    );
  }

  if (React.isValidElement(element)) {
    const { children } = element.props;

    if (!children) return element;

    if (typeof children === 'string') {
      return React.cloneElement(
        element,
        { key },
        createTruncatedTextNode(
          children,
          len,
          readMore,
          color,
          hideReadText,
          readMoreLabel,
          key,
        ),
      );
    }

    if (Array.isArray(children)) {
      let charCount = 0;
      const truncatedChildren = [];

      for (let i = 0; i < children.length; i += 1) {
        const elementLength = getElementTextLength(children[i]);

        if (charCount + elementLength > len) {
          const partial = getPartialElement(
            children[i],
            len - charCount,
            readMore,
            color,
            hideReadText,
            readMoreLabel,
            key,
          );
          truncatedChildren.push(partial);
          break;
        }
        truncatedChildren.push(children[i]);
        charCount += elementLength;
      }

      return React.cloneElement(element, { key }, truncatedChildren);
    }
  }

  return element;
}

export const contentTypeMapping = {
  person: 'people',
  book: 'books',
  application: 'apps',
  podcast: 'podcasts',
  activity: 'articles',
  video: 'videos',
  organization: 'organizations',
  topics: 'topics',
  reads: 'articles',
  events: 'events',
  FAQ: 'faqs',
  assessments: 'assessments',
  program: 'onlinePrograms',
  landing: 'landing',
  list: 'lists',
  editors: 'editors',
  blog: 'insights',
  news: 'articles',
  article: 'articles',
};

// Utility function to parse inline styles from a string to an object
export const parseStyleString = styleString => {
  const styleObject = {};
  styleString.split(';').forEach(style => {
    const [key, value] = style.split(':');
    if (key && value) {
      styleObject[key.trim()] = value.trim();
    }
  });
  return styleObject;
};

// Check if a string contains valid HTML
export const isValidHTML = htmlString => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};
