import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import _get from 'lodash/get';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  clientBadgeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
  },
  clientBadgeWrapperBorder: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: ({ size }) => size,
    width: ({ size }) => size,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
  },
  clientBadge: {
    maxHeight: ({ size }) => size,
    maxWidth: ({ size }) => size,
    objectFit: 'contain',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#000',
    border: `1px solid #d7d7d7`,
    borderRadius: 5,
    opacity: '1 !important',
    fontSize: 13,
    margin: '8px',
  },
  popper: {
    zIndex: 9999,
  },
  arrow: {
    '&::before': {
      border: `1px solid #d7d7d7`,
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
    },
  },
  topLeft: {
    top: 8,
    left: 12,
  },
  topRight: {
    top: 4,
    right: 4,
  },
}));

const TooltipBadge = ({
  title,
  image,
  customBadge,
  size = 24,
  position = 'topLeft',
}) => {
  const classes = useStyles({
    size,
  });

  const clientDetails = useSelector(makeSelectClientDetails());
  const clientLanding = useSelector(makeSelectClientLanding());
  const [userResourcesSiteCopy] = useSiteCopySelector(['user-resources']);

  const userTerm = _get(clientLanding, 'userTerm');
  const finalTitle =
    title ||
    _get(userResourcesSiteCopy, 'pageCopy.clientFactoidsLabel')
      .replace('<clientTitle>', _get(clientDetails, 'name', ''))
      .replace('<userTerm>', userTerm || '');

  return (
    <Tooltip
      title={finalTitle}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        popper: classes.popper,
      }}
      placement="top"
    >
      <div
        className={cn(classes.clientBadgeWrapper, {
          [classes.clientBadgeWrapperBorder]: !customBadge,
          [classes.topLeft]: position === 'topLeft',
          [classes.topRight]: position === 'topRight',
        })}
      >
        <img alt="" src={image} className={classes.clientBadge} />
      </div>
    </Tooltip>
  );
};

export default TooltipBadge;
