import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _some from 'lodash/some';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import cn from 'classnames';
import 'simplebar/dist/simplebar.min.css';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useWindowSize from 'components/useWindowSize';
import TopicCard from '../TopicCard';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 0',
    },
  },
  cards: {
    padding: '0 15px',
  },
  header: {
    marginBottom: 10,
    padding: '0 15px',
  },
  cardLists: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  lists: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
    columns: 3,
    [theme.breakpoints.down('xs')]: {
      columns: 2,
    },
  },
  image: {
    width: '100%',
    height: 139,
    marginBottom: 6,
  },
  listItem: {
    ...theme.typography.body2,
    overflow: 'auto',
    fontSize: 14,
    padding: '2px 15px',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    '&:hover': {
      background: '#ECF4F8',
      color: '#01619B',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '4px 8px',
    },
  },
  activeList: {
    background: '#ECF4F8',
    color: '#01619B',
    fontFamily: 'MadaBold',
  },
  dividerWrapper: {
    display: 'block',
    padding: '5px  15px',
  },
  divider: {
    borderTop: '1px solid #E9E9E9',
  },
  avoidBreak: {
    display: 'inline-block',
  },
}));

function TopicLists({ data, clickHandler, refCallback, showAll = false }) {
  const classes = useStyles();
  const { width } = useWindowSize();
  const clientDetails = useSelector(makeSelectClientDetails());
  const { siteCopy } = useContext(NavBarContext);
  if (!data) return null;
  const excludeTopicsList = _get(
    clientDetails,
    'excludeTopicCollection.items',
    [],
  ).map(el => el.title);

  const filteredList = data.topicsCollection.items
    .filter(el => !!el)
    .filter(el => !excludeTopicsList.includes(el.title))
    .filter(el => _get(el, 'reviewStatus') === 'Accepted');
  const itemToDisplay = width < 970 ? 3 : 4;
  const clonedList = [...filteredList];

  const sortedList = clonedList
    .sort((a, b) => b.userRating - a.userRating)
    .slice(0, itemToDisplay);
  const finalFilteredList = showAll
    ? filteredList
    : filteredList.filter(
        el => !_some(sortedList, item => item.slug === el.slug),
      );

  return (
    <div className={classes.root} ref={refCallback} data-test-id="topics-list">
      <Hidden xsDown>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.header}
        >
          {_get(siteCopy, ['pageCopy', 'submenu-topics', 'top-topics'])}
        </Typography>
        <div className={classes.cards}>
          <TopicCard data={sortedList} clickHandler={clickHandler} />
        </div>
        {finalFilteredList.length > 0 && (
          <div className={classes.dividerWrapper}>
            <hr className={classes.divider} />
          </div>
        )}
      </Hidden>

      <div>
        <SimpleBar style={{ maxHeight: width >= 600 ? 240 : 'unset' }}>
          <Hidden xsDown>
            {finalFilteredList.length > 0 && (
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.header}
              >
                {_get(siteCopy, ['pageCopy', 'submenu-topics', 'more-topics'])}
              </Typography>
            )}
          </Hidden>
          <ul className={classes.lists}>
            {finalFilteredList.map(({ title, slug }, index) => (
              <li
                key={slug}
                className={cn(classes.listItem, {
                  [classes.avoidBreak]: index === 0,
                })}
              >
                <Link
                  to={`/topics/${slug}`}
                  onClick={() => clickHandler(title, 'text')}
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </SimpleBar>
      </div>
    </div>
  );
}

TopicLists.propTypes = {
  data: PropTypes.object,
  clickHandler: PropTypes.func,
  showAll: PropTypes.bool,
};

export default TopicLists;
