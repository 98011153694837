/**
 *
 * AssessmentItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getAssessmentName } from 'containers/Assessment/utils';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { fallbackImage } from 'utils/stringUtils';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 60,
    width: 60,
    marginRight: 12,
    objectFit: 'cover',
  },
  title: {
    fontSize: 14,
    lineHeight: '18px',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 36 /* Fallback for non-webkit */,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 60,
    width: 'calc(100% - 72px)',
  },
  textLink: {
    fontSize: 11,
    lineHeight: '14px',
    '& > svg': {
      fontSize: 14,
    },
  },
});

const AssessmentItem = ({
  data,
  onClick,
  variantELocale,
  siteCopyInOtherLang,
}) => {
  const classes = useStyles();
  const locale = useAlgoliaLocale();
  const [siteCopy] = useSiteCopySelector(['resource-item-page']);
  const clientDetails = useSelector(makeSelectClientDetails());

  if (_isEmpty(data)) return null;

  const { url, nameTitle, customNames, imageUrl } = data;

  const handleClick = () => {
    onClick({
      type: 'Assessments',
      name: nameTitle,
      entryId: data.objectID,
    });
  };

  const finalName = getAssessmentName(
    getAlgoliaLocalizedField(data, 'nameTitle', variantELocale || locale),
    customNames,
    clientDetails.shortName,
  );

  return (
    <Link
      to={`${url}?lang=${variantELocale || locale}`}
      className={classes.link}
      onClick={handleClick}
    >
      <div className={classes.wrapper}>
        <img
          src={getEmbedlyImageUrl(imageUrl) || fallbackImage}
          alt=""
          className={classes.image}
          onError={e => {
            e.target.src = fallbackImage;
          }}
        />
        <div className={classes.textWrapper}>
          <Typography color="textPrimary" className={classes.title}>
            {finalName}
          </Typography>
          <Typography color="primary" className={classes.textLink}>
            {_get(
              siteCopyInOtherLang || siteCopy,
              'pageCopy.takeAssessmentLabel',
            )}{' '}
            <ArrowRight />
          </Typography>
        </div>
      </div>
    </Link>
  );
};

AssessmentItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default AssessmentItem;
