/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import ClassNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import _get from 'lodash/get';
import useWindowSize from 'components/useWindowSize';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getAllSeries } from 'containers/Series/selectors';
import { getAllCourses } from 'containers/Course/selectors';
import { getAllPractices } from 'containers/Practices/selectors';
import { useSelector } from 'react-redux';
import {
  hasLearningLabResources,
  makeSelectLeavingModal,
} from 'containers/Main/selectors';
import useClientExcludedResourceType from 'components/Hooks/useClientExcludedResourceType';
import useZoomLevel, { ZOOM_THRESHOLD } from 'components/Hooks/useZoomLevel';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import AccountMenu from './AccountMenu';
import MobileBottomNavBar from './MobileBottomNavBar';
import MenuItemTooltip from './MenuItemTooltip';
import Assessments from './SubMenu/Assessments';
import Blogs from './SubMenu/Blogs';
import ClientResources from './SubMenu/ClientResources';
import LearningLab from './SubMenu/LearningLab';
import NavBarContext from '../navBarContext';
import News from './SubMenu/News';
import Topics from './SubMenu/Topics';
import ArrowDown from '../images/arrow-down.svg';
import ArrowUp from '../images/arrow-up.svg';
import { ArrowDownMenuIcon } from '../images/ArrowDownMenu';
import { getTextWidth } from '../utils';
import { useKeyboardNavigation } from '../hooks/useKeyboardNavigation';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    // borderTop: '1px solid #E3E3E3',
    alignItems: 'center',
    userSelect: 'none',
    [theme.breakpoints.down('600')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      // borderTop: '1px solid #F1F1F1',
      width: '100%',
    },
  },
  zoomed: {
    maxHeight: '15vh',
  },
  embedded: {
    borderTop: 'none',
  },
  root: {
    height: 49,
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lists: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mobileList: {
    flexDirection: 'column',
    padding: '30px 5.5%',
  },
  mobileListItem: {
    ...theme.typography.body2,
    fontSize: 17,
    margin: '5px 0',
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  listItem: {
    ...theme.typography.body2,
    marginRight: props => props.listMargin,
    color: '#6C6C6C',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '10px !important',
    },
  },
  link: {
    ...theme.typography.body1,
    fontSize: 20,
    color: '#6C6C6C',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  moreLinks: {
    whiteSpace: 'unset !important',
  },
  inCrisisLink: {
    ...theme.typography.body1,
    fontSize: 20,
    fontFamily: 'MadaBold',
    color: '#AD0000 !important',
    textDecoration: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    background: theme.palette.text.white,
    borderRadius: 2,
    maxHeight: props => props.menuHeight || '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 2px 4px rgba(0,0,0,0.20)',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      width: '100%',
      paddingBottom: '20%',
      display: 'block',
    },
    [theme.breakpoints.down('380')]: {
      paddingBottom: '25%',
    },
  },
  popover: {
    zIndex: 9999,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      transform: props =>
        `translate3d(0, ${props.popoverTopMargin}, 0) !important`,
    },
    '&[x-placement="bottom"]': {
      [theme.breakpoints.up('1200')]: {
        left: '2% !important',
      },
      [theme.breakpoints.up('1400')]: {
        left: '10% !important',
      },
    },
  },
  mobilePopover: {
    marginTop: 0,
    minWidth: 230,
    [theme.breakpoints.down('xs')]: {
      transform: 'translate3d(0, 149px, 0) !important',
    },
  },
  activeItem: {
    color: theme.palette.primary.main,
    borderTop: '1px solid #01619B',
    marginTop: '-1px',
  },
  moreBtn: {
    height: '100%',
    marginRight: '0 !important',
  },
  activeMoreBtn: {
    borderTop: '1px solid #01619B',
    '& p': {
      color: theme.palette.primary.main,
    },
  },
  cursor: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  embeddedRightContent: {
    display: 'flex',
  },
  newLabel: {
    position: 'absolute',
    top: 2,
    right: -10,
    fontSize: 9,
    lineHeight: '12px',
    backgroundColor: '#2EBD59',
    color: theme.palette.text.white,
    borderRadius: 4,
    padding: '0 2px',
  },
  menuArrowButton: {
    borderRadius: 0,
    padding: 0,
    marginLeft: 8,
    outlineOffset: 1,
    '&:hover': {
      background: theme.palette.text.white,
    },
  },
}));

const POPPER_INITIAL_STATE = {
  open: false,
  slug: null,
  placement: null,
};

const ENFORCE_BOTTOM_PLACEMENT_SLUGS = ['insights', 'news'];

const MORE_BUTTON_ID = 'show-more-button';
const MORE_ITEM_ID_PREFIX = 'navbar-more';

const NO_POPOVER_SLUGS = [
  'home',
  'covid-19',
  'how-it-works',
  'in-crisis',
  'client-tools',
];
function SubMenu({ menu, isAdmin }) {
  const {
    clientDetails,
    sendMixpanelEvent,
    clientResources,
    clientLanding,
    siteCopy,
    isEmbed,
    getLink,
  } = useContext(NavBarContext);
  const courses = useSelector(getAllCourses);
  const practices = useSelector(getAllPractices);
  const series = useSelector(getAllSeries);
  const theme = useTheme();
  const { zoom } = useZoomLevel();
  const listRef = useRef();
  const [popperState, setPopperState] = useState(POPPER_INITIAL_STATE);
  const [showMore, setShowMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();
  const [maxWidth, setMaxWidth] = useState(null);
  const clientResourceMenuRef = useRef(null);
  const submenuRef = useRef(null);

  const history = useHistory();
  const { width, height } = useWindowSize();

  const trackingProps = { section: 'menu' };

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const isLearningLabEnabled = useSelector(hasLearningLabResources);
  const excludedMenuItems = isLearningLabEnabled ? [] : ['learning-lab'];

  const { visible } = useSelector(makeSelectLeavingModal());
  const isXs = width < 600;
  const isSmOrMd = width >= 600 && width < 1280;
  const popoverTopMargin =
    useAppEmbedStyle && !isXs
      ? '70px'
      : useAppEmbedStyle && isXs
      ? '85px'
      : '135px';
  const [
    userResourcesSiteCopy,
    adminSiteCopy,
    learningLabSiteCopy,
  ] = useSiteCopySelector([
    'user-resources',
    'admin-navbar-menu',
    'learning-lab',
  ]);

  const newLabel = _get(learningLabSiteCopy, 'pageCopy.newLabel');

  let isCovidCenter = _get(clientLanding, 'slug', '') === 'covid-19';
  const formalShortName = _get(clientDetails, 'context.formalShortName', '');
  const userTerm =
    formalShortName && formalShortName.length <= 12
      ? formalShortName
      : _get(clientLanding, 'userTerm', '') || '';
  const headerHeight = document.querySelector('header')?.clientHeight || 201;

  const classes = useStyles({
    menuHeight: height - headerHeight - 20,
    popoverTopMargin,
    listMargin: menu.length >= 7 ? 24 : 36,
  });
  useEffect(() => {
    clearRef();
    isCovidCenter = _get(clientLanding, 'slug', '') === 'covid-19';
  }, [location, clientLanding]);

  useEffect(() => {
    if (showMore) setShowMore(false);
  }, [width]);

  const menuWidth = clientResourceMenuRef.current;
  useEffect(() => {
    if (menuWidth && visible) {
      const { offsetWidth } = menuWidth;
      setMaxWidth(offsetWidth + 1); // Add 1px more to compensate letter spacing
    }
  }, [menuWidth, visible]);

  const handleClickMenuItem = (e, { slug, name }) => {
    e.preventDefault();
    sendMixpanelEvent('Menu Click', {
      type: name,
    });

    history.push(getLink(slug));
    clearRef();
  };

  const handleClick = ({ target, slug, name, hasPopover, event }) => {
    if (event) {
      sendMixpanelEvent(`Menu ${capitalizeFirstLetter(event)}`, {
        type: name,
      });
    }

    if (hasPopover && (event === 'focus' || target)) {
      setAnchorEl(target);
      setPopperState({
        open: true,
        slug,
        placement: isSmOrMd
          ? 'bottom'
          : ENFORCE_BOTTOM_PLACEMENT_SLUGS.includes(slug)
          ? 'bottom'
          : 'bottom-start',
      });
      setShowMore(false);
    }
  };

  const handleClickAway = isMorePopper => e => {
    if (isMorePopper) {
      if (
        showMore &&
        !_get(e, 'target.className', '')
          .split(' ')
          .includes(classes.moreBtn) &&
        !(
          !_get(e, 'target.parentNode') ||
          _get(e, 'target.parentNode.className', '')
            .split(' ')
            .includes(classes.moreBtn)
        )
      )
        setShowMore(false);
    } else if (
      popperState.open &&
      !_get(e, 'target.className', '')
        .split(' ')
        .includes(classes.cursor)
    )
      clearRef();
  };

  const clearRef = () => {
    setAnchorEl(null);
    setPopperState(POPPER_INITIAL_STATE);
    setShowMore(false);
  };

  const handleSmalleMenuClick = name => {
    sendMixpanelEvent('Menu Click', {
      type: name,
    });
  };

  const toggleShowmore = event => {
    setPopperState(POPPER_INITIAL_STATE);
    setAnchorEl(event?.type === 'click' ? event.currentTarget : event.target);
    setShowMore(!showMore);
  };

  const handleHoverItem = slug => {
    setHoveredItem(slug);
  };

  const handleClosePopover = () => {
    clearRef();
  };

  const content = slug => {
    const contentComponent = {
      insights: <Blogs onClose={handleClosePopover} />,
      'learning-lab': <LearningLab onClose={handleClosePopover} />,
      news: <News onClose={handleClosePopover} />,
      assessments: (
        <Assessments
          onClose={handleClosePopover}
          trackingProps={trackingProps}
        />
      ),
      topics: <Topics onClose={handleClosePopover} />,
      // client-tools:
      'client-exclusive': <ClientResources onClose={handleClosePopover} />,
    };

    return contentComponent[slug] || null;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const emptyChecker = (slug, name) =>
    (slug === 'client-exclusive' &&
      (_isEmpty(clientDetails) ||
        _isEmpty(clientResources) ||
        isCovidCenter)) ||
    _isEmpty(name);

  const getMenuName = (slug, name) =>
    slug === 'client-exclusive'
      ? _get(userResourcesSiteCopy, 'pageCopy.title').replace(
          '<userTerm>',
          userTerm,
        )
      : name;

  const showInCrisisInMainNavigation = useMemo(
    () => _get(clientDetails, 'metadata.inCrisisMainNavigation', false),
    [clientDetails],
  );

  const parsedMenu = menu.reduce((acc, { slug, name, ...fields }) => {
    const initialName =
      _get(
        isAdmin ? adminSiteCopy : siteCopy,
        ['pageCopy', 'submenu-labels', slug],
        '',
      ) || name;

    const shouldHideInCrisis =
      slug === 'in-crisis' && !showInCrisisInMainNavigation;

    if (
      excludedMenuItems.includes(slug) ||
      emptyChecker(slug, initialName) ||
      ((slug === 'learning' || shouldHideInCrisis) && !isXs)
    )
      return acc;

    const menuName = getMenuName(slug, initialName);

    acc.push({
      slug,
      name: menuName,
      ...fields,
    });

    return acc;
  }, []);

  const { isResourceTypeExcluded } = useClientExcludedResourceType();
  const clonedMenu = parsedMenu.filter(item => !isXs || item.slug !== 'home');
  const menuSlugs = clonedMenu.map(item => item.name).join();

  const ACSplice = useMemo(() => {
    let splice = 99;
    let sum = 0;

    if (submenuRef.current) {
      splice = 0;

      const submenuWidth = submenuRef.current.getBoundingClientRect().width;
      const accountWidth = width < 1200 ? 140 : 210;
      for (let i = 0; i < clonedMenu.length; i += 1) {
        const style =
          clonedMenu[i].slug === 'in-crisis'
            ? '700 20px MadaBold'
            : `${theme.typography.body1.fontWeight} 20px ${theme.typography.body1.fontFamily}`;
        const textWidth = Math.ceil(getTextWidth(clonedMenu[i].name, style));
        const finalTextWidth = i > 0 ? textWidth + 44 : textWidth;
        if (sum + finalTextWidth < submenuWidth - accountWidth) {
          sum += finalTextWidth;
          splice += 1;
        } else {
          break;
        }
      }
      if (
        splice !== clonedMenu.length &&
        sum + 91 > submenuWidth - accountWidth
      ) {
        splice -= 1;
      }
    }
    return splice;
  }, [width, menuSlugs]);

  const getHandleArrowClick = ({ slug, name, openSubmenu }) => event => {
    const targetLi = event?.target?.closest('li');
    if (openSubmenu && targetLi) {
      handleClick({
        target: targetLi,
        slug,
        name,
        hasPopover: true,
      });
      sendMixpanelEvent(`Menu Expand Click`, {
        type: name,
      });
      return;
    }
    handleClosePopover();
    sendMixpanelEvent(`Menu Collapse Click`, {
      type: name,
    });
  };

  const getHandleFocus = ({ slug, name }) => e => {
    const isFromKeyboard = e.relatedTarget !== null;

    if (isFromKeyboard) {
      handleClick({
        slug,
        name,
        event: 'focus',
      });
    }
  };

  const navCallback = useKeyboardNavigation({
    moreButtonId: MORE_BUTTON_ID,
    moreItemIdPrefix: MORE_ITEM_ID_PREFIX,
    toggleShowmore,
    setShowMore,
  });

  const shrinkingMenu = ACSplice ? clonedMenu.splice(0, ACSplice) : clonedMenu;
  const finalMenu = (isXs || clonedMenu.length === 0
    ? parsedMenu
    : shrinkingMenu
  ).filter(item => !isResourceTypeExcluded(item.slug));

  const isMenuPopoverDisabled = menuSlug => {
    if (menuSlug === 'client-exclusive')
      return _get(clientDetails, 'metadata.disableUserResourcesSubMenu', false);
    if (menuSlug === 'learning-lab') {
      const count =
        (courses.length > 0 ? 1 : 0) +
        (series.length > 0 ? 1 : 0) +
        (practices.length > 0 ? 1 : 0);

      if (count < 2) return true;
    }

    return false;
  };

  const isClientActive = _get(clientDetails, 'activeClient') ?? true;
  if (!isClientActive) return null;

  return (
    <div
      className={ClassNames(classes.container, {
        [classes.embedded]: useAppEmbedStyle,
        [classes.zoomed]: zoom > ZOOM_THRESHOLD,
      })}
      data-test-id="sub-menu"
      ref={submenuRef}
    >
      <Hidden xsDown>
        <nav
          aria-label="Main navigation"
          ref={navCallback}
          className={classes.root}
        >
          <ul className={classes.lists} ref={listRef} role="menu">
            {finalMenu.map(({ slug, name, link }) => {
              const hasNoPopover =
                NO_POPOVER_SLUGS.includes(slug) ||
                link ||
                isMenuPopoverDisabled(slug);

              return (
                <li
                  key={slug}
                  id={id}
                  className={ClassNames(classes.listItem, {
                    [classes.activeItem]: popperState.slug === slug,
                  })}
                  ref={ref => {
                    if (slug === 'client-exclusive') {
                      clientResourceMenuRef.current = ref;
                    }
                  }}
                  role="none"
                  style={{
                    maxWidth:
                      maxWidth && slug === 'client-exclusive'
                        ? `${maxWidth}px`
                        : 'none',
                  }}
                  onMouseEnter={() => {
                    handleHoverItem(slug);
                  }}
                  onMouseLeave={() => {
                    handleHoverItem(null);
                  }}
                  onFocus={() => {
                    handleHoverItem(slug);
                  }}
                  onBlur={() => {
                    handleHoverItem(null);
                  }}
                >
                  {hasNoPopover ? (
                    <Link
                      to={link || getLink(slug)}
                      onClick={() =>
                        handleClick({
                          slug,
                          name,
                          hasPopover: false,
                          event: 'click',
                        })
                      }
                      role="menuitem"
                    >
                      <Typography
                        variant="h2"
                        role="presentation"
                        className={
                          slug === 'in-crisis'
                            ? classes.inCrisisLink
                            : classes.link
                        }
                      >
                        {name}
                      </Typography>
                    </Link>
                  ) : (
                    <>
                      <ButtonBase
                        onClick={e => handleClickMenuItem(e, { slug, name })}
                        className={classes.cursor}
                        data-test-id={`${name.toLowerCase()}-menu`}
                        type="button"
                        disableRipple
                        role="menuitem"
                        component="span"
                      >
                        <Typography
                          role="presentation"
                          variant="h2"
                          className={classes.link}
                        >
                          {name}
                        </Typography>
                      </ButtonBase>
                      <IconButton
                        className={classes.menuArrowButton}
                        onClick={getHandleArrowClick({
                          slug,
                          name,
                          openSubmenu: !popperState?.open,
                          event: 'click',
                        })}
                        role="menuitem"
                        onFocus={getHandleFocus({ slug, name })}
                        aria-label={`${name} menu dropdown`}
                        disableRipple
                        id={`menu-dropdown-${slug}`}
                      >
                        <ArrowDownMenuIcon isHovered={hoveredItem === slug} />
                      </IconButton>
                    </>
                  )}
                  {slug === 'learning-lab' && (
                    <Typography component="span" className={classes.newLabel}>
                      {newLabel}
                    </Typography>
                  )}
                </li>
              );
            })}
            {clonedMenu.length > 0 && (
              <li role="none">
                <ButtonBase
                  disableTouchRipple
                  onClick={toggleShowmore}
                  className={ClassNames(classes.listItem, classes.moreBtn, {
                    [classes.activeMoreBtn]: showMore,
                  })}
                  role="menuitem"
                  id={MORE_BUTTON_ID}
                >
                  <Typography>
                    {_get(siteCopy, ['pageCopy', 'submenu-labels', 'more'])}
                  </Typography>
                  <img src={showMore ? ArrowUp : ArrowDown} alt="" />
                </ButtonBase>
                <Popper
                  open={showMore}
                  anchorEl={anchorEl}
                  className={ClassNames(classes.popover, classes.mobilePopover)}
                  placement="bottom-start"
                  disablePortal
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'window',
                    },
                  }}
                >
                  <ClickAwayListener
                    touchEvent="onTouchStart"
                    mouseEvent="onMouseDown"
                    onClickAway={handleClickAway(true)}
                  >
                    <div className={classes.paper}>
                      <ul
                        className={ClassNames(
                          classes.lists,
                          classes.mobileList,
                        )}
                        role="menu"
                      >
                        {clonedMenu
                          .filter(({ slug }) => slug !== 'home')
                          .map(({ slug, name, link }) => (
                            <li
                              className={classes.mobileListItem}
                              role="none"
                              key={slug}
                            >
                              <Link
                                to={link || getLink(slug)}
                                className={ClassNames(
                                  slug === 'in-crisis'
                                    ? classes.inCrisisLink
                                    : classes.link,
                                  classes.moreLinks,
                                )}
                                role="menuitem"
                                id={`${MORE_ITEM_ID_PREFIX}-${slug}`}
                                onClick={() => handleSmalleMenuClick(name)}
                              >
                                {name}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </ClickAwayListener>
                </Popper>
              </li>
            )}
          </ul>
        </nav>
        <MenuItemTooltip
          popperState={popperState}
          anchorEl={anchorEl || document.activeElement}
          classes={classes}
          onClose={handleClosePopover}
          content={content}
        />
      </Hidden>
      <Hidden smUp>
        <MobileBottomNavBar subMenuItems={finalMenu} />
      </Hidden>
      {!isAdmin && (
        <Hidden xsDown>
          <div className={useAppEmbedStyle ? classes.embeddedRightContent : ''}>
            <AccountMenu />
          </div>
        </Hidden>
      )}
    </div>
  );
}

SubMenu.propTypes = {
  menu: PropTypes.array,
};
export default React.memo(SubMenu);
